/* eslint-disable prefer-rest-params */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */

import { useEffect } from 'react';

import isClient from 'commons/isClient';
import getEnvValue from 'commons/getEnvValue';

const SCRIPT_ID = 'affirmScript';

const loadAffirmScript = cb => {
  if (!isClient()) return;

  if (document.getElementById(SCRIPT_ID)) {
    if (cb) cb();
    return;
  }

  const isSb = window?.location?.search?.indexOf('jb_sb=1') >= 0;

  const affirm_config = {
    public_api_key: !isSb
      ? getEnvValue('NEXT_PUBLIC_AFFIRM_PUB_KEY') ??
        getEnvValue('REACT_APP_AFFIRM_PUB_KEY')
      : getEnvValue('NEXT_PUBLIC_AFFIRM_PUB_KEY_SANDBOX') ??
        getEnvValue('REACT_APP_AFFIRM_PUB_KEY_SANDBOX'),
    script: !isSb
      ? getEnvValue('NEXT_PUBLIC_AFFIRM_SCRIPT_URL') ??
        getEnvValue('REACT_APP_AFFIRM_SCRIPT_URL')
      : getEnvValue('NEXT_PUBLIC_AFFIRM_SCRIPT_URL_SANDBOX') ??
        getEnvValue('REACT_APP_AFFIRM_SCRIPT_URL_SANDBOX'),
  };

  (function(l, g, m, e, a, f, b) {
    var d,
      c = l[m] || {},
      h = document.createElement(f),
      n = document.getElementsByTagName(f)[0],
      k = function(a, b, c) {
        return function() {
          a[b]._.push([c, arguments]);
        };
      };
    c[e] = k(c, e, 'set');
    d = c[e];
    c[a] = {};
    c[a]._ = [];
    d._ = [];
    c[a][b] = k(c, a, b);
    a = 0;
    for (
      b = 'set add save post open empty reset on off trigger ready setProduct'.split(
        ' '
      );
      a < b.length;
      a++
    )
      d[b[a]] = k(c, e, b[a]);
    a = 0;
    for (b = ['get', 'token', 'url', 'items']; a < b.length; a++)
      d[b[a]] = function() {};
    h.async = !0;
    /* custom code start */
    h.id = SCRIPT_ID;
    h.onload = () => {
      if (cb) cb();
    };
    /* custom code end */
    h.src = g[f];
    n.parentNode.insertBefore(h, n);
    delete g[f];
    d(g);
    l[m] = c;
  })(window, affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready');
};

const useAffirmScript = ({ onLoad } = {}) => {
  useEffect(() => {
    loadAffirmScript(onLoad);
  }, [onLoad]);
};

export default useAffirmScript;
