import React from 'react';

import imageComingSoon from 'assets/icons/image-coming-soon.svg';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const ProductCardImageComingSoon = () => (
  <img
    alt=""
    className="absolute w-[80%] h-[80%] top-[10%] left-[10%]"
    src={
      isNewConsumerApp
        ? '/static/assets/icons/image-coming-soon.svg'
        : imageComingSoon
    }
  />
);

export default ProductCardImageComingSoon;
