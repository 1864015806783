import { useEffect, useState } from 'react';

import { logError } from 'commons/logger';

// Module-level flag to ensure override only happens once
let hasOverridden = false;

const parsePostHogQuery = query => {
  try {
    const queryObject = JSON.parse(query);

    if (queryObject?.clear === true) {
      return false;
    }

    return queryObject;
  } catch (err) {
    logError(err, 'Unable to parse posthogOverrides query', {
      query,
    });

    // clear overrides in for any malformed query
    return false;
  }
};

const usePostHogFeatureFlagWithPayload = name => {
  const [value, setValue] = useState(null);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    const removePostHogEventListeners = [];

    const intervalId = setInterval(() => {
      if (!window.posthog) return;

      clearInterval(intervalId);

      const postHogOverrideQuery = new URLSearchParams(
        window.location.search
      ).get('posthogOverrides');

      const removePostHogEventListener = window.posthog.onFeatureFlags(() => {
        // Only override once across all instances
        if (
          !hasOverridden &&
          postHogOverrideQuery &&
          postHogOverrideQuery !== '' &&
          postHogOverrideQuery !== '{}' &&
          window.posthog.featureFlags
        ) {
          hasOverridden = true;
          const parsedQuery = parsePostHogQuery(postHogOverrideQuery);
          if (parsedQuery !== false) {
            window.posthog.featureFlags.override(parsedQuery);
          }
        }
        // feature flags are guaranteed to be available here
        if (window.posthog.isFeatureEnabled(name)) {
          const val = window.posthog.getFeatureFlag(name);
          const payloadValue = window.posthog.getFeatureFlagPayload(name);
          if (val !== null) {
            setValue(val);
            setPayload(payloadValue);
          }
        }
      });

      removePostHogEventListeners.push(removePostHogEventListener);
    }, 500);

    return () => {
      clearInterval(intervalId);

      removePostHogEventListeners.forEach(removePostHogEventListener => {
        removePostHogEventListener?.();
      });
    };
  }, [name]);

  return [value, payload];
};

export default usePostHogFeatureFlagWithPayload;
