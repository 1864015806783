import algoliasearch from 'algoliasearch';
import getEnvValue from 'commons/getEnvValue';

export const algoliaClient = algoliasearch(
  getEnvValue('NEXT_PUBLIC_ALGOLIA_APPID') ??
    getEnvValue('REACT_APP_ALGOLIA_APPID'),
  getEnvValue('NEXT_PUBLIC_ALGOLIA_APIKEY') ??
    getEnvValue('REACT_APP_ALGOLIA_APIKEY')
);

export const searchClient = {
  search(requests) {
    return algoliaClient.search(requests);
  },
};

export const noEmptySearchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          query: '',
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};
