import { useCallback } from 'react';
import { useThrottle, useWindowScroll } from 'react-use';

const useThrottledWindowScrollPosition = ({ callback, time = 100 }) => {
  const { x = 0, y = 0 } = useWindowScroll();

  const useThrottleCallback = useCallback(() => {
    callback({ x, y });
  }, [callback, x, y]);

  useThrottle(useThrottleCallback, time, [x, y]);
};

export default useThrottledWindowScrollPosition;
